<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <input type="file" class="form form-control" @change="onChange" />
            </div>
            <div class="col-auto text-right">
              <button class="btn btn-secondary" @click="$router.go(-1)">
                Kembali
              </button>
              <button class="btn btn-primary" @click="doUpload">Import</button>
            </div>
          </div>
          <div class="progress mb-5 mt-5" v-if="currentProgres > 0">
            <div
              class="progress-bar"
              role="progressbar"
              :style="`width: ${currentProgres}%`"
              :aria-valuenow="currentProgres"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ currentProgres }} %
            </div>
          </div>
          <vue-excel-editor
            class="mt-5"
            v-if="jsondata && currentProgres == 0"
            v-model="jsondata"
            filter-row
          />
          <button v-if="logMessage.length > 0" @click="showLogs = !showLogs">
            show logs
          </button>
          <div v-if="showLogs">
            <pre>
            {{ logMessage }}
          </pre
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { read, utils } from "xlsx";
import Service from "@/services/base.service";

export default {
  components: {
    // VueExcelEditor,
  },
  data() {
    return {
      file: null,
      jsondata: null,
      logMessage: [],
      tahun: "",
      currentProgres: 0,
      showLogs: false,
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = read(bstr, { type: "binary" });
          // /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // /* Convert array of arrays */
          const data = utils.sheet_to_json(ws, { header: 1 });
          this.jsondata = data;
        };
        reader.readAsBinaryString(this.file);
      }
      // console.log(event);
    },
    async doUpload() {
      const rawData = toRaw(this.jsondata);
      const service = new Service("serial");
      let counterData = 0;
      const totalData = rawData.length - 1;

      for (const row of rawData.slice(1)) {
        const params = {
          serial_number: row[0],
          note: row[1] ?? "",
        };
        try {
          const { message, data } = await service.createData(params);
          this.logMessage.push(`Create data | ${message} | #${data.id}`);
        } catch (error) {
          this.logMessage.push(`Failed Create data | ${error}`);
        }
        ++counterData;
        this.currentProgres = (counterData / totalData) * 100;
      }
    },
  },
};
</script>
